import anime from 'animejs'

(() => {
  let animation

  document.addEventListener('DOMContentLoaded', () => {
    const header = document.querySelector('body > header')
    const footer = document.querySelector('body > footer')

    animation = anime({
      targets: [header, footer],
      backgroundSize: [
        '100% 100%',
        '150% 150%'
      ],
      backgroundColor: [
        '#8A2387',
        '#db6b1d'
      ],
      duration: 3000,
      direction: 'alternate',
      loop: true,
      easing: 'easeInOutSine'
    })
  })

  window.addEventListener('blur', () => {
    if (typeof animation !== 'undefined' && animation !== null) {
      animation.pause()
    }
  })

  window.addEventListener('focus', () => {
    if (typeof animation !== 'undefined' && animation !== null) {
      animation.play()
    }
  })
})()
